import styled from 'styled-components';
import { motion } from 'framer-motion';

interface ScBenefitsProps {
	inverted?: boolean;
	lastElement?: boolean;
}

export const ScBenefitsCardContainer = styled.div<ScBenefitsProps>`
	display: flex;
	border-bottom: ${({ theme, lastElement }) =>
		lastElement ? 'none' : `solid 2px ${theme.colors.wheat}`};
`;

export const ScBenefitsCardWrapper = styled(motion.section)<ScBenefitsProps>`
	display: flex;
	flex-direction: column-reverse;
	gap: 20px;

	padding: 0 20px;
	margin: 80px 0;

	img {
		max-height: 200px;
		max-width: 100%;
	}

	@media (min-width: 768px) {
		display: grid;
		direction: ${({ inverted }) => (inverted ? 'rtl' : 'ltr')};
		grid-template-columns: 1fr 1fr;
		gap: 60px;
		align-items: center;

		img {
			max-height: 400px;
			max-width: 100%;
		}
	}

	@media (min-width: 1000px) {
		padding: 0 55px;
	}

	@media (min-width: 1400px) {
		padding: 0 110px;
	}
`;

export const ScBenefitsCardTextWrapper = styled.div<ScBenefitsProps>`
	display: flex;
	flex-direction: column;
	gap: 30px;
	text-align: left;

	h2 {
		font: ${({ theme }) => theme.fonts.heading.smallPlus};
		direction: ltr;
	}

	p {
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		direction: ltr;
	}

	button {
		font: ${({ theme }) => theme.fonts.button.small};
		padding: 24px 32px;
		height: 56px;
	}

	@media (min-width: 375px) {
		button {
			font: ${({ theme }) => theme.fonts.button.medium};
			height: 66px;
		}
	}

	@media (min-width: 420px) {
		button {
			font: ${({ theme }) => theme.fonts.button.mediumPlus};
			padding: 24px 40px;
		}
	}

	@media (min-width: 768px) {
		align-items: ${({ inverted }) => (inverted ? 'end' : 'start')};

		button {
			padding: 24px 28px;
		}
	}

	@media (min-width: 820px) {
		button {
			padding: 24px 40px;
		}
	}
`;

export const ScImageWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
