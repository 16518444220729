import React from 'react';
import { Link } from 'gatsby';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import { CustomButton } from '../custom-button';
import { ScParagraph } from '../Paragraph/styled';
import { ScTitle } from '../Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import {
	ScBenefitsCardContainer,
	ScBenefitsCardTextWrapper,
	ScBenefitsCardWrapper,
	ScImageWrapper,
} from './styled';

export interface IBenefitsCard {
	title: string;
	text: string;
	image: string;
	link: string;
	buttonText: string;
	inverted?: boolean;
	lastElement?: boolean;
}

export const BenefitsCard = ({
	title,
	text,
	image,
	link,
	buttonText,
	inverted,
	lastElement,
}: IBenefitsCard) => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsCardContainer lastElement={lastElement}>
			<ScBenefitsCardWrapper
				initial="hidden"
				animate={controls}
				variants={animationContainer}
				inverted={inverted}
			>
				<MotionDiv variants={inverted ? fadeInLeft : fadeInRight}>
					<ScBenefitsCardTextWrapper inverted={inverted}>
						<ScTitle>{title}</ScTitle>
						<ScParagraph>
							<p ref={ref}>{text}</p>
						</ScParagraph>
						<Link to={link}>
							<CustomButton>{buttonText}</CustomButton>
						</Link>
					</ScBenefitsCardTextWrapper>
				</MotionDiv>
				<ScImageWrapper>
					<MotionImage
						src={image}
						alt="employee bike hero"
						variants={inverted ? fadeInRight : fadeInLeft}
					/>
				</ScImageWrapper>
			</ScBenefitsCardWrapper>{' '}
		</ScBenefitsCardContainer>
	);
};
