import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import actLogo from '../../assets/images/act-logo.png';
import bCorp from '../../assets/images/b-corp-logo.png';
import { ScCardWrapper, ScImageWrapper, ScSchemeInfoWrapper, ScTextWrapper } from './styled';
import { ScTitle } from '../../components/Title/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { MotionDiv, MotionImage } from '../../animations/components';

export const SchemeInfo = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScContainer>
			<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
				<ScSchemeInfoWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTextWrapper>
							<ScTitle>
								Not your average <br /> cycle to work scheme
							</ScTitle>
							<ScParagraph>
								<p>
									Cycle to work gives employees an extra slice of their salary by paying for bikes
									and accessories out of their gross pay. But not all cycle to work schemes are
									created equal. Gogeta Bike is the only cycle to work scheme with a fully flexible
									voucher and the only scheme that is backed by the bike industry and B Corp
									accredited.
								</p>
							</ScParagraph>
						</ScTextWrapper>
					</MotionDiv>

					<ScImageWrapper>
						<MotionImage src={actLogo} alt="act-logo" variants={fadeInRight} />
						<MotionImage src={bCorp} alt="b-corp" variants={fadeInLeft} />
					</ScImageWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScCardWrapper>
							<ScParagraph>
								<p>
									I founded Gogeta because, as a bike retailer, I saw firsthand the harm that big,
									legacy cycle to work schemes caused to independent retailers. High commission fees
									charged by these legacy schemes often left retailers with little choice but to
									pass on costs to customers. Determined to find a better solution, I created Gogeta
									- a cycle to work scheme that offers superior savings to employees while
									supporting, not punishing, retailers.
								</p>
							</ScParagraph>
							<ScParagraph centered>
								<b>Barry Scott - Gogeta Founder</b>
							</ScParagraph>
						</ScCardWrapper>
					</MotionDiv>
				</ScSchemeInfoWrapper>
			</motion.section>
		</ScContainer>
	);
};
