import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import bikeSavings from '../../assets/images/bike-savings.png';
import promise from '../../assets/images/promise-3.png';
import bikeHelmet from '../../assets/images/bike-logo.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';

const items: IBenefitsCard[] = [
	{
		title: 'Great savings, zero retailer surcharges',
		text: 'Save up to 40% on bikes and cycling accessories by paying for them out of your gross salary, before tax and NI contributions. And because we offer the best deal to retailers, they pass on the best deals to you.',
		image: bikeSavings,
		link: '/employee-cycling/',
		buttonText: 'Take me to the employee page',
	},
	{
		title: 'An extra slice for employees, at no cost to you',
		text: "Help make employees salaries go further by letting them pay for bikes and accessories out of their gross salary. It's free to sign up and won't cost you a penny.",
		image: promise,
		link: '/employer-cycling/',
		buttonText: 'Take me to the employer page',
	},
	{
		title: 'Join our retailer network',
		text: 'Our retailer commission is just 3% (same as a credit card) because we believe the cycle to work scheme needs to work for everyone, including the bike shops that support it.',
		image: bikeHelmet,
		link: '/provider-cycling/',
		buttonText: 'Take me to the retailer page',
	},
];

export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<Top className="top" />
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
								buttonText={buttonText}
								inverted={index % 2 !== 0}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
			<Bot />
		</ScBenefitsContainer>
	);
};
